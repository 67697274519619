// const offerCards = document.querySelectorAll('.offer__list__card');
// offerCards.forEach(card => {
//     card.addEventListener('click', () => {
//         card.classList.toggle('expanded');
//     });
// });



$(function () {
    var Accordion = function (el, multiple) {
        this.el = el || {};
        this.multiple = multiple || false;

        var links = this.el.find('.js-accordion-open');
        links.on(
            'click',
            { el: this.el, multiple: this.multiple },
            this.dropdown
        );
    };

    Accordion.prototype.dropdown = function (e) {
        var $el = e.data.el;
        var $this = $(this),
            $next = $this.find('.js-accordion-content');

        $next.slideToggle(300);
        $this.toggleClass('-open');

        if (!e.data.multiple) {
            $el.find('.js-accordion-content')
                .not($next)
                .slideUp()
                .closest('.js-accordion-open')
                .removeClass('-open');
        }
    };

    var accordion = new Accordion($('.js-offer-accordion'), false);
});
